import React from "react";
import { Pagination, Table } from "react-bootstrap";
import DashboardLayout from "../../components/Layout/DashboardLayout";

const MyOrder = () => {
  return (
    <DashboardLayout>
      <h2>Recent Orders</h2>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>Order #</th>
            <th>Date</th>
            <th>Ship To</th>
            <th>Order Total</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 10 }).map((_, index) => (
            <tr key={index}>
              <td>000004825</td>
              <td>8/10/21</td>
              <td>Rach Test G8</td>
              <td>$146.51</td>
              <td>Processing</td>
              <td>
                <div className="action-group">
                  <button>View Order</button>
                  <button>Reorder</button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="pagination-wrap">
        <Pagination className="justify-content-center">
          <Pagination.Prev />
          <Pagination.Item active>{1}</Pagination.Item>
          <Pagination.Item>{2}</Pagination.Item>
          <Pagination.Item>{3}</Pagination.Item>
          <Pagination.Item>{4}</Pagination.Item>
          <Pagination.Item>{5}</Pagination.Item>
          <Pagination.Ellipsis className="dots" />
          <Pagination.Item>{12}</Pagination.Item>
          <Pagination.Next />
        </Pagination>
        <p className="text-sm text-secondary">Show 10 of 20</p>
      </div>
    </DashboardLayout>
  );
};

export default MyOrder;
